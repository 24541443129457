.post-item-ctr * {
    text-align: left;
    /* margin: 0 auto; */
}
/* .post-item-ctr > * {
    border: 1px solid red;
} */

.post-item-ctr-background {
    background: var(--pearl-color);
    padding: 15px;
    border-radius: 15px;
    margin: 15px 0;
}

.post-item-ctr {
    margin: auto auto;
    width: 80vw;
    max-width: 800px;

}

.location, .date {
    /* font-style: italic; */
    margin: 0;
}

.post-title {
    margin-bottom: 3px;
}

.post-image {
    /* width: 80vw; */
    max-width: 800px;
    margin-top: 8px;
    max-height: 80vh;
    /* max-height: 800px; */
}

.comment-like-ctr {
    margin: auto auto;
    width: 80vw;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.post-icon {
    font-size: 25px;
}

.liked-icon {
    color: red;
}

.unliked-icon:hover {
    color: var(--pink-color);
}

.post-tags {
    /* margin: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    /* padding-left: 10px; */
}

.post-tag {
    background-color: var(--background);
    border-radius: 15px;
    padding: 8px;
}

.post-body {
    margin: 0;
    white-space: pre-wrap;
}

/* button.show-more {
    background-color: var(--background);
    border: 1px solid black;
    border-radius: 10px;
    color: blue;
} */

@media only screen and (max-width: 935px) {
    .post-image {
        max-width: 80vw;
    }
}