.posts-new-page-ctr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.posts-new-page-ctr .new-post {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 15px;
}

.posts-new-page-ctr form label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.posts-new-page-ctr .tags-ctr {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 15px;
}

.rendered-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.tag-card {
    border: 1px solid black;
    border-radius: 25px;
    padding: 5px;
}

.inactive-tag {
    background-color: rgb(236, 210, 199);
}

.active-tag {
    background-color: lightsalmon;
    color: white;
}