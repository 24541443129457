a {
  text-decoration: none;
  color: black;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background);
}

.flex {
  display: flex;
}

.flex-ctr-ctr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.hidden {
  display: none;
}