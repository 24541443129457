footer {
    /* background-color: rgba(232, 227, 215, 1.0); */
    background-color: (--background);
    margin-top: auto;
    color: black;
    bottom: 0;
    display: sticky;
    width: 100%;
    height: 50px;
    gap: 15px;
}

.social-media-ctr {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

footer > h4 {
    font-size: 1.25rem;
}

.social-media-icon {
    color: black;
    font-size: 18px;
    transition: 0.75s;
}

.social-media-icon:hover {
    /* background-color: lightsalmon; */
    /* border-radius: 5px; */
    /* color: lightsalmon; */
    font-size: 25px;
    transition: 0.25s
}

.footer-text {
    font-size: 12px;
}