nav {
    gap: 20px;
    /* border: 1px solid red; */
    height: 50px
}

nav .link {
    padding: 0 15px;
    text-decoration: none;
    color: black;
}

nav .right-align {
    margin-left: auto;
}

nav .logo {
    font-size: 28px;
}

/* nav .login-logout, nav .about {
    margin-left: auto;
} */