*{
  box-sizing: border-box;
}

:root {
  --background: rgba(239, 235, 230, 1.0);
  --pearl-color: rgba(246, 246, 244, 1.0);
  --pink-color: rgb(251, 149, 149);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
